var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[(_vm.showData.type === 'image')?_c('img',{style:({
        position: 'absolute',
        top: _vm.previewData[_vm.index].y + 'px',
        left: _vm.previewData[_vm.index].x + 'px',
        width: _vm.previewData[_vm.index].w + 'px',
        height: _vm.previewData[_vm.index].h + 'px',
        zIndex: _vm.previewData[_vm.index].boxId,
        objectFit: 'fill',
      }),attrs:{"src":_vm.showData.img,"alt":"content"}}):_vm._e(),(_vm.showData.type === 'video')?_c('video',{style:({
        position: 'absolute',
        top: _vm.previewData[_vm.index].y + 'px',
        left: _vm.previewData[_vm.index].x + 'px',
        width: _vm.previewData[_vm.index].w + 'px',
        height: _vm.previewData[_vm.index].h + 'px',
        zIndex: _vm.previewData[_vm.index].boxId,
        objectFit: 'fill',
      }),attrs:{"src":_vm.showData.img,"autoplay":"","loop":_vm.previewData[_vm.index].content_sorting.length === 1,"muted":""},domProps:{"muted":true},on:{"ended":_vm.handleVideoEnd}}):_vm._e(),(_vm.showData.type === 'application')?_c('iframe',{ref:"iframe",style:({
        position: 'absolute',
        top: _vm.previewData[_vm.index].y + 'px',
        left: _vm.previewData[_vm.index].x + 'px',
        width: _vm.previewData[_vm.index].w + 'px',
        height: _vm.previewData[_vm.index].h + 'px',
        zIndex: _vm.previewData[_vm.index].boxId,
      }),attrs:{"src":_vm.showData.img}}):_vm._e(),(_vm.showData.type === 'Html')?_c('iframe',{ref:"iframe",style:({
        position: 'absolute',
        top: _vm.previewData[_vm.index].y + 'px',
        left: _vm.previewData[_vm.index].x + 'px',
        width: _vm.previewData[_vm.index].w + 'px',
        height: _vm.previewData[_vm.index].h + 'px',
        zIndex: _vm.previewData[_vm.index].boxId,
      }),attrs:{"src":_vm.showData.proxy_url}}):(_vm.showData.type !== 'application')?_c('iframe',{ref:"iframe",style:({
        position: 'absolute',
        top: _vm.previewData[_vm.index].y + 'px',
        left: _vm.previewData[_vm.index].x + 'px',
        width: _vm.previewData[_vm.index].w + 'px',
        height: _vm.previewData[_vm.index].h + 'px',
        zIndex: _vm.previewData[_vm.index].boxId,
      }),attrs:{"src":_vm.showData.url}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }