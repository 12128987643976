<template>
  <div class="modal-overlay">
    <div class="modal-container">
      <div class="model-header">
        <button class="close-button" @click="$emit('closePreviewModal')">
          <i class="tim-icons icon-simple-remove"></i>
        </button>
      </div>
      <div class="canvas-preview-detail" v-if="privewData">
        <div
          :style="{
            position: 'relative',
            width: privewData.width + 'px',
            height: privewData.height + 'px',
            border: '1px solid black',
            overflow: 'hidden',
          }"
        >
          <div
            v-for="(preview, index) in previewData"
            :key="preview.boxId + index"
          >
            <preview-design-content :previewData="previewData" :index="index" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewDesignContent from "./previewDesignContent/PreviewDesignContent.vue";
export default {
  components: {
    PreviewDesignContent,
  },
  data() {
    return {
      image: "",
      design: {},
      dropdownresol: this.previewDetails[0].dropdownresol,
      availImages: [],
      currentIndex: 0,
      stopInterval: null,
      previewData: [],
    };
  },
  props: {
    privewData: {
      type: ImageData,
      required: true,
    },
    previewDetails: {
      type: Array,
    },
    design_id: {},
  },

  mounted() {
    this.previewDetails.map((newData) => {
      name = newData.containername;
      this.availImages.push({
        name: newData.containername,
        length: newData.containerData.content_sorting.length,
        index: 0,
      });
    });
    this.renderContent();

    this.stopInterval = setInterval(() => {
      this.previewDetails.map((newData, containerIndex) => {
        this.availImages.map((data, dataIndex) => {
          if (data.name === newData.containername) {
            this.availImages[dataIndex].index = (data.index + 1) % data.length;
          }
        });
      });
    }, 10000);
  },

  beforeDestroy() {
    clearInterval(this.stopInterval);
  },

  methods: {
    renderContent() {
      const sortedDetails = [...this.previewDetails].sort(
        (a, b) => a.containerDesign.boxId - b.containerDesign.boxId
      );
      this.previewData = [];
      const transformedData = sortedDetails.reduce((acc, item) => {
        const boxId = item.containerDesign.boxId;
        const x = item.containerDesign.x;
        const y = item.containerDesign.y;
        const w = item.containerDesign.w;
        const h = item.containerDesign.h;

        if (!acc[boxId]) {
          acc[boxId] = {
            content_sorting: [],
            x,
            y,
            w,
            h,
          };
        }

        acc[boxId].content_sorting.push(...item.containerData.content_sorting);

        return acc;
      }, {});
      const result = Object.entries(transformedData).map(([boxId, data]) => {
        return {
          boxId: Number(boxId),
          content_sorting: data.content_sorting,
          x: data.x,
          y: data.y,
          w: data.w,
          h: data.h,
        };
      });
      this.previewData = result;
    },
    closeModal() {
      this.$emit("closePreviewModal");
    },
  },
};
</script>
<style>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.modal-container {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  position: relative;
}

.model-header {
  width: 100%;
  height: 2.5rem;
  position: relative;
  display: flex;
  justify-content: end;
}

.close-button {
  margin: 10px 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.canvas-preview-detail {
  padding: 20px;
  border-top: 1px solid #e9e9e9;
  width: 100%;
}

iframe {
  object-fit: cover;
}
</style>
