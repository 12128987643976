<template>
  <div>
    <h1>
      <img
        v-if="showData.type === 'image'"
        :src="showData.img"
        :style="{
          position: 'absolute',
          top: previewData[index].y + 'px',
          left: previewData[index].x + 'px',
          width: previewData[index].w + 'px',
          height: previewData[index].h + 'px',
          zIndex: previewData[index].boxId,
          objectFit: 'fill',
        }"
        alt="content"
      />
      <video
        v-if="showData.type === 'video'"
        :src="showData.img"
        :style="{
          position: 'absolute',
          top: previewData[index].y + 'px',
          left: previewData[index].x + 'px',
          width: previewData[index].w + 'px',
          height: previewData[index].h + 'px',
          zIndex: previewData[index].boxId,
          objectFit: 'fill',
        }"
        autoplay
        :loop="previewData[index].content_sorting.length === 1"
        muted
        @ended="handleVideoEnd"
      ></video>
      <iframe
        v-if="showData.type === 'application'"
        ref="iframe"
        :style="{
          position: 'absolute',
          top: previewData[index].y + 'px',
          left: previewData[index].x + 'px',
          width: previewData[index].w + 'px',
          height: previewData[index].h + 'px',
          zIndex: previewData[index].boxId,
        }"
        :src="showData.img"
      ></iframe>
      <iframe
        v-if="showData.type === 'Html'"
        ref="iframe"
        :style="{
          position: 'absolute',
          top: previewData[index].y + 'px',
          left: previewData[index].x + 'px',
          width: previewData[index].w + 'px',
          height: previewData[index].h + 'px',
          zIndex: previewData[index].boxId,
        }"
        :src="showData.proxy_url"
      ></iframe>
      <iframe
        v-else-if="showData.type !== 'application'"
        ref="iframe"
        :style="{
          position: 'absolute',
          top: previewData[index].y + 'px',
          left: previewData[index].x + 'px',
          width: previewData[index].w + 'px',
          height: previewData[index].h + 'px',
          zIndex: previewData[index].boxId,
        }"
        :src="showData.url"
      ></iframe>
    </h1>
  </div>
</template>
<script>
export default {
  name: "preview-design-content",
  data() {
    return {
      previewIndex: 0,
      showData: this.previewData[this.index].content_sorting[0],
      previewDetails: this.previewDetails,
      timerId: null,
    };
  },
  props: {
    previewData: {
      type: Array,
    },
    index: Number,
  },
  mounted() {
    this.renderData();
  },
  beforeDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
  methods: {
    renderData() {
      const length = this.previewData[this.index].content_sorting.length;
      if (length > 1 && this.showData.type !== "video") {
        this.timerId = setTimeout(() => {
          this.previewIndex = (this.previewIndex + 1) % length;
          this.showData =
            this.previewData[this.index].content_sorting[this.previewIndex];
          this.renderData();
        }, this.previewData[this.index].content_sorting[this.previewIndex].duration);
      }
    },
    handleVideoEnd() {
      const length = this.previewData[this.index].content_sorting.length;
      this.previewIndex = (this.previewIndex + 1) % length;
      this.showData =
        this.previewData[this.index].content_sorting[this.previewIndex];
    },
  },
};
</script>