<template>
  <div class="mt-3">
    <h4>{{ $t("EDIT PLAYLIST") }}</h4>
    <form @submit.prevent>
      <card class="stacked-form">
        <div class="row">
          <div class="col-12">
            <div style="display: flex">
              <base-input
                style="width: 90%"
                :label="$t('Enter Playlist Name')"
                v-model="getplaylist.playlist_details.name"
              />
              <div style="width: auto" class="mt-3 ml-2">
                <base-button
                  @click="save()"
                  native-type="submit"
                  :disabled="getplaylist.content_sorting.length === 0"
                  type="primary"
                  >{{ $t("Submit") }}</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </card>
      <div class="row">
        <div class="col-6">
          <card class="stacked-form table-responsive" title="CONTENT LISTING">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <el-select
                  class="select-primary mb-3 pagination-select"
                  v-model="pagination.perPage"
                  :placeholder="$t('Per page')"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <base-input>
                  <el-input
                    type="search"
                    class="mb-3 search-input"
                    clearable
                    prefix-icon="el-icon-search"
                    :placeholder="$t('Search records')"
                    v-model="query"
                    aria-controls="datatables"
                  >
                  </el-input>
                </base-input>
              </div>

              <modal
                :show.sync="openimagemodal"
                class="modal-search"
                id="setscheduler"
                :centered="false"
                :show-close="true"
              >
                <div
                  v-if="selectedImage"
                  max-width="85vw"
                  style="text-align: center"
                >
                  <img :src="selectedImage" alt="" width="100%" height="auto" />

                  <br />
                  <br />
                  <hr />
                  <base-button
                    @click="openimagemodal = false"
                    class=""
                    type="primary"
                    >{{ $t("Close") }}</base-button
                  >
                </div>
              </modal>

              <modal
                :show.sync="openvideomodal"
                class="modal-search"
                id="setscheduler"
                :centered="false"
                :show-close="true"
              >
                <div
                  v-if="selectedVideo"
                  max-width="85vw"
                  style="text-align: center"
                >
                  <iframe
                    :src="selectedVideo"
                    height="500px"
                    width="100%"
                    title="Iframe Example"
                  ></iframe>

                  <br />
                  <br />
                  <hr />
                  <base-button @click="closemodal()" class="" type="primary">{{
                    $t("Close")
                  }}</base-button>
                </div>
              </modal>

              <modal
                :show.sync="openappmodal"
                class="modal-search"
                id="setscheduler"
                :centered="false"
                :show-close="true"
              >
                <div
                  v-if="selectedapp"
                  max-width="85vw"
                  style="text-align: center"
                >
                  <iframe
                    :src="selectedapp"
                    height="390px"
                    width="400px"
                    title="Iframe Example"
                  ></iframe>

                  <br />
                  <br />
                  <hr />
                  <base-button
                    @click="openappmodal = false"
                    class=""
                    type="primary"
                    >{{ $t("Close") }}</base-button
                  >
                </div>
              </modal>

              <div id="app">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        @click="sortChange(table)"
                        v-for="(table, key) in tableData.title"
                        :key="key"
                        class="table-header"
                      >
                        <span>
                          {{ $t(table)
                          }}<SortIcon
                            :topColor="computedTopColor(table, 'top')"
                            :bottomColor="computedTopColor(table, 'bottom')"
                          />
                        </span>
                      </th>
                      <th @click="sortChange('type')" class="table-header">
                        <span>
                          {{ $t("Type")
                          }}<SortIcon
                            :topColor="computedTopColor('type', 'top')"
                            :bottomColor="computedTopColor('type', 'bottom')"
                          />
                        </span>
                      </th>
                      <th @click="sortChange('preview')" class="table-header">
                        <span>
                          {{ $t("Preview")
                          }}<SortIcon
                            :topColor="computedTopColor('preview', 'top')"
                            :bottomColor="computedTopColor('preview', 'bottom')"
                          />
                        </span>
                      </th>
                      <th class="text-center">{{ $t("Schedule") }}</th>
                      <th class="text-center">{{ $t("action") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(table, key) in tableData.data"
                      :key="table.id"
                      v-if="key !== 'title'"
                    >
                      <template v-for="t in table">
                        <td v-if="t.name === 'name'" :key="t.id">
                          {{ t.contentparamvalue }}
                        </td>
                        <td
                          v-else
                          :key="t.id + 'zends'"
                          style="max-width: 325px; padding: 0 1.2rem"
                        >
                          {{ t.contentparamvalue }}
                        </td>
                      </template>
                      <td v-if="table[0].mime == 'Html'">
                        <i
                          style="color: var(--primary)"
                          class="tim-icons icon-credit-card"
                        ></i>
                      </td>
                      <td v-if="table[0].mime == 'Url'">
                        <i
                          style="color: var(--primary)"
                          class="tim-icons icon-credit-card"
                        ></i>
                      </td>
                      <td v-else-if="table[0].mime == 'image'">
                        <i
                          style="color: var(--primary)"
                          class="tim-icons icon-image-02"
                        ></i>
                      </td>
                      <td v-else-if="table[0].mime == 'video'">
                        <i
                          style="color: var(--primary)"
                          class="tim-icons icon-video-66"
                        ></i>
                      </td>
                      <td v-else-if="table[0].mime == 'application'">
                        <i
                          style="color: var(--primary)"
                          class="tim-icons icon-notes"
                        ></i>
                      </td>
                      <td v-if="table[0].mime == 'video'">
                        <a
                          href="javascript:void()"
                          @click="videopreview(table[0].content_file)"
                          ><video
                            :src="table[0].content_file"
                            height="50px"
                            width="50px"
                        /></a>
                      </td>
                      <td v-else-if="table[0].mime == 'application'">
                        <a
                          href="javascript::void)"
                          @click="apppreview(table[0].content_file)"
                          ><img
                            @click="apppreview(table[0].content_file)"
                            src="/img/pdf-icon.png"
                            width="40px"
                            height="45px"
                        /></a>
                      </td>
                      <td v-else-if="table[0].mime == 'Url'">
                        <a :href="table[0].url" target="_blank">
                          {{ $t("link") }}</a
                        >
                      </td>
                      <td v-else-if="table[0].mime == 'Html'">
                        <a target="_blank" :href="table[0].url">{{
                          $t("link")
                        }}</a>
                      </td>
                      <td v-else-if="table[0].mime == 'image'">
                        <img
                          style="
                            display: inline;
                            object-fit: contain;
                            border-radius: 0;
                          "
                          :src="table[0].content_file"
                          alt="img"
                          height="50px"
                          width="50px"
                          @click="zoom(table[0].content_file)"
                        />
                      </td>
                      <td v-else-if="table[0].mime == 'application'">
                        <img
                          @click="apppreview(table[0].content_file)"
                          style="display: inline"
                          src="https://api.zends.eu/storage/app/public/app.png"
                          width="50px"
                          height="50px"
                        />
                      </td>
                      <td v-else-if="table[0].mime == ''">
                        <a
                          href="http://zends.eu/content/list-content"
                          target="_blank"
                          >{{ $t("link") }}</a
                        >
                      </td>
                      <td class="text-center">
                        <i
                          style="color: black"
                          class="tim-icons icon-simple-remove"
                        ></i>
                      </td>
                      <td class="text-center">
                        <base-button
                          title="Edit"
                          class="'details btn-link"
                          type="success"
                          style="color: var(--primary)"
                        >
                          <i
                            @click="
                              add(
                                table[0].content_id,
                                table[0].contentparamvalue,
                                table[0].content_file,
                                table[0].mime
                              )
                            "
                            class="tim-icons icon-simple-add m-3"
                            title=""
                            style="color: var(--primary)"
                          ></i>
                        </base-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                slot="footer"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    {{ $t("Showing") }} {{ from + 1 }} {{ $t("to") }} {{ to }}
                    {{ $t("of") }} {{ total }} {{ $t("entries") }}
                  </p>
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total="total"
                >
                </base-pagination>
              </div>
            </div>
          </card>
        </div>
        <div class="col-6 content-sorting">
          <card class="stacked-form" title="CONTENT SORTING">
            <h4 class="total-duration" v-if="totalDuration !== '0 seconds'">
              Total: {{ totalDuration }}
            </h4>
            <div
              class="col-12 justify-content-center justify-content-sm-between flex-wrap"
            >
              <draggable v-model="getplaylist.content_sorting">
                <transition-group name="flip-transition">
                  <ul
                    :key="key"
                    class="list-group"
                    v-for="(data, key) in getplaylist.content_sorting"
                  >
                    <li class="list-group-item">
                      <div class="row row-content">
                        <div class="col-md-2 content-section">
                          <div class="content-img" style="margin-top: -1px">
                            <img
                              style="
                                display: inline;
                                object-fit: contain;
                                border-radius: 0;
                              "
                              v-if="data.type == 'image'"
                              :src="data.img"
                              height="50px"
                              width="50px"
                            />
                            <img
                              v-else-if="data.type == 'application'"
                              src="/img/pdf-icon.png"
                              width="40px"
                              height="45px"
                            />
                            <video
                              style="
                                display: inline;
                                object-fit: contain;
                                border-radius: 0;
                              "
                              v-else-if="data.type == 'video'"
                              :src="data.img"
                              class="video-container"
                              height="50px"
                              width="50px"
                            />
                            <img
                              style="
                                display: inline;
                                object-fit: contain;
                                border-radius: 0;
                              "
                              v-else-if="data.type == 'application'"
                              src="https://api.zends.euzends.eu/storage/app/public/app.png"
                              height="50px"
                              width="50px"
                            />
                            <a v-else href="javascript::void()">{{
                              $t("link")
                            }}</a>
                          </div>
                        </div>
                        <span
                          class="text-title font-weight-bold text-responstive"
                          style="align-self: center"
                          :class="data.type == 'video' && 'vedio-text'"
                          >{{ data.name }} - {{ data.type }}
                        </span>
                      </div>
                    </li>
                    <span v-if="data.type == 'video'">
                      <a href="javascript:void(0)" @click="remove(key)"
                        >Remove</a
                      >
                      |
                      <a
                        href="javascript:void(0)"
                        id="setscheduler"
                        @click="modal(data.content_id)"
                        >{{ $t("Set
                        Scheduler") }}</a
                      >
                    </span>
                    <span v-else-if="data.type == 'image'">
                      <a href="javascript:void(0)" @click="remove(key)">{{
                        $t("Remove")
                      }}</a>
                      |
                      <a
                        href="javascript:void(0)"
                        id="setscheduler"
                        @click="modal(data.content_id)"
                        >{{ $t("Set
                        Scheduler") }}</a
                      >
                      |
                      <a
                        href="javascript:void(0)"
                        id="setduration"
                        @click="
                          modalduration(
                            data.content_id,
                            data.unique_id,
                            data.name
                          )
                        "
                        >{{ $t("Duration") }}</a
                      >
                      <!-- |
                    <a
                      href="javascript:void(0)"
                      id="setduration"
                      @click="modaleffects(data.content_id)"
                      >Set Effects</a
                    > -->
                    </span>
                    <span v-else>
                      <a href="javascript:void(0)" @click="remove(key)">{{
                        $t("Remove")
                      }}</a>
                      |
                      <a
                        href="javascript:void(0)"
                        id="setscheduler"
                        @click="modal(data.content_id)"
                        >{{ $t("Set
                        Scheduler") }}</a
                      >
                      |
                      <a
                        href="javascript:void(0)"
                        id="setduration"
                        @click="
                          modalduration(
                            data.content_id,
                            data.unique_id,
                            data.name
                          )
                        "
                        >{{ $t("Duration") }}</a
                      >
                    </span>
                  </ul>
                </transition-group>
              </draggable>
            </div>
          </card>
        </div>
      </div>

      <modal
        :show.sync="openmodal"
        class="modal-search"
        id="setscheduler"
        :centered="false"
        :show-close="true"
        :modalContentClasses="widthplay100"
      >
        <div class="row table-full-width">
          <h3>{{ $t("Scheduling Settings") }}</h3>

          <div class="col-md-12">
            <el-table :data="secondTableData">
              <el-table-column width="400" label="Typology" align="center">
                <div class="" style="text-align: justify" slot-scope="{ row }">
                  <div>
                    <!-- <base-radio  v-model="radios.display" name="1" inline>Display</base-radio> -->
                    <input
                      value="1"
                      id="sf1"
                      type="radio"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxhide()"
                    />
                    <label for="sf1">{{ $t("Always run") }}</label>
                  </div>
                  <div>
                    <input
                      value="2"
                      id="sf2"
                      type="radio"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxselect()"
                    />
                    <label for="sf2">{{ $t("Run if checked") }}</label
                    ><br />
                  </div>
                  <div>
                    <input
                      value="3"
                      id="sf3"
                      type="radio"
                      name="scheduler_form"
                      v-model="schdular.Typologychecked"
                      @change="checkboxselect()"
                    />
                    <label for="sf3">{{
                      $t("Do not execute if selected")
                    }}</label
                    ><br />
                  </div>
                  <div class="row">
                    <div class="col-md-7">
                      <el-date-picker
                        type="date"
                        :placeholder="$t('Date Picker')"
                        v-model="schdular.datePickerStart"
                        :disabled="row.ckdisabled"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </div>
                    <div class="col-md-5">
                      <el-time-select
                        :disabled="row.ckdisabled"
                        v-model="schdular.timePickerStart"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '23:59',
                        }"
                        :placeholder="$t('Select time')"
                      >
                      </el-time-select>
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-md-7">
                      <el-date-picker
                        type="date"
                        :disabled="row.ckdisabled"
                        :placeholder="$t('Date Picker')"
                        v-model="schdular.datePickerEnd"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                      >
                      </el-date-picker>
                    </div>
                    <div class="col-md-5">
                      <el-time-select
                        :disabled="row.ckdisabled"
                        v-model="schdular.timePickerEnd"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '23:59',
                        }"
                        :placeholder="$t('Select time')"
                      >
                      </el-time-select>
                    </div>
                  </div>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Lun" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Lunchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Mar" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Marchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Mer" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Merchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Gio" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Giochecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>

              <el-table-column width="100" label="Ven" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Venchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
              <el-table-column width="100" label="Sab" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Sabchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
              <el-table-column width="100" label="Dom" align="center">
                <div slot-scope="{ row }" class="checkbox-cell">
                  <base-checkbox
                    v-model="schdular.Domchecked"
                    :disabled="row.ckdisabled"
                  ></base-checkbox>
                </div>
              </el-table-column>
            </el-table>
          </div>

          <div class="text-right col-md-12">
            <base-button @click="test()" class="" type="primary">{{
              $t("Close")
            }}</base-button>
            <base-button
              class=""
              native-type="submit"
              type="primary"
              @click="addschdular(), (openmodal = false)"
            >
              {{ $t("Confirmation") }}</base-button
            >
          </div>
        </div>
      </modal>
      <modal
        :show.sync="openmodalduration"
        class="modal-search"
        id="setduration"
        :centered="false"
        :show-close="true"
      >
        <div>
          <h3>{{ $t("ENTER THE DURATION") }}</h3>
          <hr />
          <div>
            <h4 class="ml-3">{{ name }}</h4>
            <div>
              <vue-timepicker
                class="timepicker-without-scrollbar"
                format="HH:mm:ss"
                v-model="duration.timePicker"
                close-on-complete
              ></vue-timepicker>
            </div>
          </div>
          <br />
          <hr />
          <div class="text-right col-md-12 duration-btn">
            <base-button
              @click="closeModalDuearion()"
              class=""
              type="primary"
              >{{ $t("Close") }}</base-button
            >
            <base-button
              class=""
              native-type="submit"
              type="primary"
              @click="
                addduration(
                  duration.contid,
                  duration.unique_id,
                  duration.timePicker
                ),
                  openmodalduration
              "
            >
              {{ $t("Confirmation") }}</base-button
            >
          </div>
        </div>
      </modal>

      <modal
        :show.sync="openmodaleffects"
        class="modal-search"
        id="setduration"
        :centered="false"
        :show-close="true"
      >
        <div>
          <h3>{{ $t("EFFECTS MANAGEMENT") }}</h3>
          <hr />
          <hr />
          <div>
            <!-- <base-input label="Email address"
                  type="email"
                  placeholder="Enter email"
                  style="border:1px solid">
      </base-input> -->
            <label for="fname" class="mr-3"
              >{{ $t("Fade in (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.fadein"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("Fade Out (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.fadeout"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("Slide in from above (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.slideinabove"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("Slide in from below (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.slideinbelow"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("Slide in from left (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.slideinleft"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("Slide in from right (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.slideinright"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("High slide out (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.highslideout"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("Low slide out (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.lowslideout"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("Left slide out (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.leftslideout"
            />
            <br />
            <label for="fname" class="mr-3"
              >{{ $t("Right slide out (seconds)") }} :</label
            >
            <input
              type="number"
              min="0"
              id="fname"
              name="fname"
              style="border: 1px solid"
              v-model="effects.rightlideout"
            />
          </div>
          <br />
          <hr />
          <div class="text-right col-md-12">
            <base-button
              @click="openmodaleffects = false"
              class=""
              type="primary"
              >{{ $t("Close") }}</base-button
            >
            <base-button
              class=""
              native-type="submit"
              type="primary"
              @click="addeffects(), openmodaleffects"
            >
              {{ $t("Insert") }}</base-button
            >
          </div>
        </div>
      </modal>
    </form>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  TimeSelect,
  TimePicker,
} from "element-ui";
import { BasePagination } from "src/components";
import draggable from "vuedraggable";
import axios from "axios";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "src/components/ValidationError.vue";
import _ from "lodash";
import VueTimepicker from "vue2-timepicker/dist/VueTimepicker.common.js";
import "vue2-timepicker/dist/VueTimepicker.css";
import { Modal } from "@/components";
import { duration } from "moment";
import SortIcon from "@/assets/customImg/SortIcon.vue";
import { getTopColor, getBottomColor } from "../../util/utils";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  mixins: [formMixin],

  components: {
    SortIcon,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [TimePicker.name]: TimePicker,
    Modal,
    ValidationError,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    draggable,
    VueTimepicker,
  },
  computed: {
    // selectedTime: {
    //   get() {
    //     // If duration.timePicker is empty, return the default time "00:00:10"
    //     return this.duration.timePicker || "00:00:10";
    //   },
    //   set(value) {
    //     // Update duration.timePicker with the selected time
    //     this.duration.timePicker = value;
    //   },
    // },
    totalDuration() {
      const totalSeconds = this.getplaylist.content_sorting
        .map((data) => data.duration || 0)
        .reduce((total, duration) => total + duration, 0);

      if (totalSeconds <= 59) {
        // Display seconds only
        return `${totalSeconds.toFixed(2)} seconds`;
      } else {
        // Display minutes and seconds
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = (totalSeconds % 60).toFixed(2);
        return `${minutes} minute ${remainingSeconds} seconds`;
      }
    },

    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.tableData.total < highBound) {
        highBound = this.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.tableData.total;
    },
  },

  data() {
    return {
      fuseSearch: null,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
      },
      getplaylist: [],
      searchedData: [],
      search: "",
      query: "",
      playlist: {
        pname: "",
        content: [],
      },
      sortingArray: [],
      openmodal: false,
      openmodalduration: false,
      openmodaleffects: false,
      isValid: true,
      tableData: "",
      widthplay100: "widthplay-100",
      secondTableData: [
        {
          Typologychecked: "1",
          Lunchecked: false,
          Marchecked: false,
          Merchecked: false,
          Giochecked: false,
          Venchecked: false,
          Sabchecked: false,
          Domchecked: false,
          ckdisabled: true,
        },
      ],

      schdular: {
        Typologychecked: 1,
        datePickerEnd: "",
        datePickerStart: "",
        timePickerStart: "",
        timePickerEnd: "",
        Lunchecked: 0,
        Marchecked: 0,
        Merchecked: 0,
        Giochecked: 0,
        Venchecked: 0,
        Sabchecked: 0,
        Domchecked: 0,
        contid: "",
      },
      duration: {
        timePicker: "00:00:10",
        contid: "",
        unique_id: "",
      },
      effectid: "",
      effects: {
        fadein: "",
        fadeout: "",
        slideinabove: "",
        slideinbelow: "",
        slideinleft: "",
        slideinright: "",
        highslideout: "",
        lowslideout: "",
        leftslideout: "",
        rightlideout: "",
      },
      searchModalVisible: true,
      selectedapp: "",
      selectedvideo: "",
      selectedimage: "",
      openappmodal: false,
      openvideomodal: false,
      openimagemodal: false,
      sortparam: {
        field: "name",
        sort: "asc",
      },
      timeOptions: [],
    };
  },
  methods: {
    computedTopColor(table, arrowIcon) {
      if (arrowIcon === "top") {
        return getTopColor(table, this.sortparam);
      } else {
        return getBottomColor(table, this.sortparam);
      }
    },
    getApiBaseUrl() {
      return url;
    },
    test() {
      this.openmodal = false;
      // this.schdular.Typologychecked = 1;
      this.schdular.datePickerEnd = "";
      this.schdular.datePickerStart = "";
      this.schdular.timePickerStart = "";
      this.schdular.timePickerEnd = "";
      this.schdular.Lunchecked = false;
      this.schdular.Marchecked = false;
      this.schdular.Merchecked = false;
      this.schdular.Giochecked = false;
      this.schdular.Venchecked = false;
      this.schdular.Sabchecked = false;
      this.schdular.Domchecked = false;
      this.schdular.contid = "";
    },
    modal(id) {
      this.openmodal = true;
      this.schdular.contid = id;
      this.getSchedularData(id);
    },

    sortChange(data) {
      this.sortparam.field = data;
      this.sortparam.sort = this.sortparam.sort == "asc" ? "desc" : "asc";
      this.getcontent();
    },

    modalduration(id, uniqueId, name) {
      const playListId = this.$route.params.id;
      this.openmodalduration = true;
      this.duration.contid = id;
      this.duration.unique_id = uniqueId;
      this.name = name;
      const itemToUpdate = this.getplaylist.content_sorting.find(
        (item) => item.unique_id === uniqueId
      );
      if (itemToUpdate.unique_id !== undefined) {
        axios
          .get(
            `${url}/get-playlist-duration/${id}/${playListId}/${itemToUpdate.unique_id}`
          )
          .then((response) => {
            this.duration.timePicker = response.data.time;
          });
      } else {
        axios
          .get(`${url}/get-playlist-duration/${id}/${playListId}`)
          .then((response) => {
            this.duration.timePicker = response.data.time;
          });
      }
    },
    closeModalDuearion() {
      this.openmodalduration = false;
      this.duration.timePicker = "00:00:10";
    },
    modaleffects(id) {
      this.openmodaleffects = true;
      this.effectid = id;
    },
    getListDebounced: _.debounce(function () {
      this.getcontent();
    }, 300),

    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },

    setCurrentPage(direction) {
      if (direction === -1 && this.currentPage > 1) {
        this.currentPage -= 1;
      } else if (
        direction === 1 &&
        this.currentPage < this.tableData.length / this.postsPerPage
      ) {
        this.currentPage += 1;
      }
    },

    async getPlaylist() {
      const id = this.$route.params.id;
      await axios
        .get(`${url}/getEdityPlaylist/${id}`)
        .then(async (response) => {
          const resData = response.data;
          const dataPromises = resData.content_sorting.map(async (e) => {
            if (e.type === "video") {
              try {
                const durationResponse =
                  e.unique_id !== null
                    ? await axios.get(
                        `${url}/get-playlist-duration/${e.content_id}/${id}/${e.unique_id}`
                      )
                    : await axios.get(
                        `${url}/get-playlist-duration/${e.content_id}/${id}`
                      );
                e.duration =
                  durationResponse.data !== "default"
                    ? this.timeStringToSeconds(durationResponse.data.time)
                    : await this.getVedioLength(e.img);
              } catch (error) {
                console.error("Error fetching video duration: ", error);
              }
            } else {
              const durationResponse =
                e.unique_id !== null
                  ? await axios.get(
                      `${url}/get-playlist-duration/${e.content_id}/${id}/${e.unique_id}`
                    )
                  : await axios.get(
                      `${url}/get-playlist-duration/${e.content_id}/${id}`
                    );
              e.duration =
                durationResponse.data !== "default"
                  ? this.timeStringToSeconds(durationResponse.data.time)
                  : 10;
            }
          });
          await Promise.all(dataPromises);
          this.getplaylist = resData;
        });
    },

    timeStringToSeconds(timeString) {
      const timeParts = timeString.split(":");

      if (timeParts.length === 3) {
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);
        const seconds = parseInt(timeParts[2], 10);

        return hours * 3600 + minutes * 60 + seconds;
      } else if (timeParts.length === 2) {
        const minutes = parseInt(timeParts[0], 10);
        const seconds = parseInt(timeParts[1], 10);

        return minutes * 60 + seconds;
      } else {
        return parseInt(timeParts[0], 10);
      }
    },

    remove(index) {
      this.getplaylist.content_sorting.splice(index, 1);
    },
    generateUniqueValue() {
      const randomString = Math.random().toString(36).substr(2, 5);
      const randomNumber = Math.floor(Math.random() * 1000);

      return `${randomString}${randomNumber}`;
    },

    async add(content_id, title, url, type) {
      const urls = process.env.VUE_APP_API_BASE_URL;
      const id = this.$route.params.id;
      let duration;
      if (type === "video") {
        try {
          const durationResponse = await axios.get(
            `${urls}/get-playlist-duration/${content_id}/${id}`
          );
          duration =
            durationResponse.data !== "default"
              ? this.timeStringToSeconds(durationResponse.data.time)
              : await this.getVedioLength(url);
        } catch (error) {
          console.error("Error fetching video duration: ", error);
        }
      } else {
        const durationResponse = await axios.get(
          `${urls}/get-playlist-duration/${content_id}/${id}`
        );
        duration =
          durationResponse.data !== "default"
            ? this.timeStringToSeconds(durationResponse.data.time)
            : 10;
      }
      this.getplaylist.content_sorting.push({
        content_id: content_id,
        img: url,
        name: title,
        type: type,
        duration: duration,
        unique_id: this.generateUniqueValue(),
      });
    },

    async getVedioLength(url) {
      return new Promise((resolve, reject) => {
        const videoUrl = url;
        const videoElement = document.createElement("video");
        videoElement.src = videoUrl;

        videoElement.addEventListener("loadedmetadata", () => {
          const videoDuration = videoElement.duration;
          const length = Number(videoDuration.toFixed(2));
          resolve(length);
        });

        videoElement.addEventListener("error", (error) => {
          reject(error);
        });
      });
    },

    checkboxselect() {
      this.secondTableData[0].ckdisabled = false;
    },
    checkboxhide() {
      this.secondTableData[0].ckdisabled = true;
      this.secondTableData[0].Lunchecked = false;
      this.secondTableData[0].Marchecked = false;
      this.secondTableData[0].Merchecked = false;
      this.secondTableData[0].Giochecked = false;
      this.secondTableData[0].Venchecked = false;
      this.secondTableData[0].Sabchecked = false;
      this.secondTableData[0].Domchecked = false;
      this.schdular.Lunchecked = false;
      this.schdular.Marchecked = false;
      this.schdular.Merchecked = false;
      this.schdular.Giochecked = false;
      this.schdular.Venchecked = false;
      this.schdular.Sabchecked = false;
      this.schdular.Domchecked = false;
      this.schdular.datePickerEnd = "";
      this.schdular.datePickerStart = "";
      this.schdular.timePickerStart = "";
      this.schdular.timePickerEnd = "";
    },
    videopreview(videourl) {
      this.selectedVideo = videourl;
      this.openvideomodal = true;
    },
    apppreview(appurl) {
      this.selectedapp = appurl;
      this.openappmodal = true;
    },
    zoom(url) {
      this.selectedImage = url;
      this.openimagemodal = true;
    },
    closemodal() {
      this.openvideomodal = false;
      this.selectedVideo = "";
    },
    async getcontent() {
      let params = {
        sort: {
          ...this.sortparam,
        },
        filter: {
          ...(this.query
            ? {
                searchValue: this.query,
              }
            : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };
      await this.$store.dispatch("contentfile/contentgetdata", params);
      this.tableData = await this.$store.getters["contentfile/content_list"];
    },

    save() {
      axios.post(`${url}/edit-playlist`, this.getplaylist).then((response) => {
        this.tableData = response.data;
        this.goBack();
      });
    },

    goBack() {
      this.$router.push({
        name: "Play List",
      });
    },

    async addschdular() {
      // Convert boolean properties to numbers in the schdular object
      this.schdular.Lunchecked = this.schdular.Lunchecked ? 1 : 0;
      this.schdular.Marchecked = this.schdular.Marchecked ? 1 : 0;
      this.schdular.Merchecked = this.schdular.Merchecked ? 1 : 0;
      this.schdular.Giochecked = this.schdular.Giochecked ? 1 : 0;
      this.schdular.Venchecked = this.schdular.Venchecked ? 1 : 0;
      this.schdular.Sabchecked = this.schdular.Sabchecked ? 1 : 0;
      this.schdular.Domchecked = this.schdular.Domchecked ? 1 : 0;

      try {
        axios
          .post(`${url}/add-playlist-schedular`, this.schdular)
          .then((response) => {
            this.$notify({
              type: "success",
              message: this.$t("Schedular added successfully."),
              icon: "tim-icons icon-bell-55",
            });
          });
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
    async addduration(id, uniqueId, time) {
      const playListId = this.$route.params.id;
      this.duration.playlist_id = Number(playListId);
      const itemToUpdate = this.getplaylist.content_sorting.find(
        (item) => item.unique_id === uniqueId
      );
      this.duration.unique_id = itemToUpdate.unique_id;
      const formattedTime = `${time.HH}:${time.mm}:${time.ss}`;
      const finalTimeDuration = time.HH === undefined ? time : formattedTime;
      this.duration.timePicker = finalTimeDuration;
      try {
        axios
          .post(`${url}/add-playlist-duration`, this.duration)
          .then((response) => {
            this.duration.timePicker = "00:00:10";
          });
        this.$notify({
          type: "success",
          message: this.$t("Duration added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        const itemToUpdate = this.getplaylist.content_sorting.find(
          (item) => item.unique_id === uniqueId
        );
        itemToUpdate.duration = this.timeStringToSeconds(finalTimeDuration);
        this.openmodalduration = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
    async addeffects() {
      try {
        var tmp = {};

        tmp["id"] = this.effectid;
        tmp["efftects"] = this.effects;
        axios.post(`${url}/add-playlist-effects`, tmp).then((response) => {});
        this.$notify({
          type: "success",
          message: this.$t("Effect added successfully."),
          icon: "tim-icons icon-bell-55",
        });
        this.openmodaleffects = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
        this.setApiValidation(e.response.data.errors);
      }
    },
    async getSchedularData(id) {
      await axios
        .get(`${url}/get-playlist-schedular/${id}/0`)
        .then((response) => {
          const data = response.data;
          if (data !== "ok") {
            this.schdular.Lunchecked = Number(data.giorni[0]);
            this.schdular.Marchecked = Number(data.giorni[1]);
            this.schdular.Merchecked = Number(data.giorni[2]);
            this.schdular.Giochecked = Number(data.giorni[3]);
            this.schdular.Venchecked = Number(data.giorni[4]);
            this.schdular.Sabchecked = Number(data.giorni[5]);
            this.schdular.Domchecked = Number(data.giorni[6]);
            this.schdular.Typologychecked = Number(data.tipo_esecuzione);
            this.schdular.datePickerStart = data.dal_giorno;
            this.schdular.timePickerStart = data.da_ora;
            this.schdular.datePickerEnd = data.al_giorno;
            this.schdular.timePickerEnd = data.a_ora;
            this.checkboxselect();
            if (data.tipo_esecuzione === "1") {
              this.checkboxhide();
            }
          } else {
            this.schdular.Typologychecked = 1;
            this.checkboxhide();
          }
        });
    },
  },

  mounted() {
    this.getPlaylist();
    this.getcontent();
    // this.getduration();
  },

  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getcontent",
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.flip-transition-move {
  transition: all 0.7s;
}

.content-img {
  width: 38%;
  margin-top: -12px;
  display: flex;
}

.list-group-item {
  max-height: 50px;
}

.list-group {
  cursor: move;
}

.text-title {
  margin-left: -62px;
  margin-top: -11px;
}

.card {
  border: 0.0625rem solid #e3e3e3;
}

.card-header {
  background-color: #e3e3e3 !important;
}

table {
  width: 100% !important;
}

.modal-search .modal-dialog {
  display: flex;
  justify-content: center;
  margin: 23px auto;
  max-width: 65% !important;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 2px 0px var(--primary) inset;
  margin-right: 3px;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 35%;
  height: 31%;
  margin: 31% auto;
  border-radius: 50%;
}

input[type="radio"]:checked:before {
  background: var(--primary);
}

.pickup > .mb-0 > input {
  border-radius: 25px;
  border: 1px solid #dddddd;
  background-color: #f9f9f9;
}

.modal-content {
  height: auto;
  width: 700px;
}

.el-input__suffix {
  right: 15px !important;
}
.el-date-editor {
  border: 1px solid rgba(34, 42, 66, 0.2) !important;
}

.vue__time-picker input.display-time {
  border: 1px solid #d2d2d2;
  width: 100%;
  height: 40px;
  padding: 0.3em 0.5em;
  font-size: 17px;
}

.clear-btn {
  position: absolute;
  top: 26px;
}

.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: #2e92ea;
  color: #fff;
}

.vue__time-picker .dropdown ul li:not([disabled]).active:hover {
  background: #2e92ea;
  color: #fff;
}

.vue__time-picker-dropdown .hint,
.vue__time-picker .dropdown .hint,
.vue__time-picker .dropdown ul li {
  font-size: 17px;
}
.timepicker-without-scrollbar ::-webkit-scrollbar {
  /* Hide the scrollbar for the time picker */
  display: none;
}

.duration-btn {
  padding-right: 0px !important;
}
</style>

<style>
.widthplay-100 {
  height: auto;
  width: 100%;
}
</style>

<style scoped>
.list-group-item {
  max-height: 100%;
  padding: 0;
}
.content-sorting {
  position: relative;
}

.content-section {
  padding: 12px 7px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.content-img {
  display: flex;
  justify-content: center;
  height: 50px;
  width: 50px;
}

.text-title {
  margin-left: -5px;
}

.total-duration {
  text-align: end;
  position: absolute;
  margin-top: -3.3rem;
  width: 90%;
}

.white-content .table > tbody > tr > td {
  padding: 0;
}

@media (max-width: 767px) {
  .row-content {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .text-responstive {
    align-self: center;
    width: 90%;
    text-align: center;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .row-content {
    margin-left: 0.5rem;
  }
}
.table-header {
  cursor: pointer;
}
.table-header span {
  display: flex;
  align-items: center;
}
</style>
