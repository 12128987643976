var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-overlay"},[_c('div',{staticClass:"modal-container"},[_c('div',{staticClass:"model-header"},[_c('button',{staticClass:"close-button",on:{"click":function($event){return _vm.$emit('closePreviewModal')}}},[_c('i',{staticClass:"tim-icons icon-simple-remove"})])]),(_vm.privewData)?_c('div',{staticClass:"canvas-preview-detail"},[_c('div',{style:({
          position: 'relative',
          width: _vm.privewData.width + 'px',
          height: _vm.privewData.height + 'px',
          border: '1px solid black',
          overflow: 'hidden',
        })},_vm._l((_vm.previewData),function(preview,index){return _c('div',{key:preview.boxId + index},[_c('preview-design-content',{attrs:{"previewData":_vm.previewData,"index":index}})],1)}),0)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }